import './home.css';
import Navbar from "../Navbar";
function Home (){

    return (
    
        <div>
            <div className="Apps">
                <header><Navbar/></header>
            
            </div>
            <div className='home'>
                <br></br>
                <p7>Healthcare Inventory Management Systems</p7>
            </div>
        </div>
        );

}
export default Home;